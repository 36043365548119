<template>
    <v-container class="pa-0" fluid>
        <!-- PAGE JUMBOTRON -->
        <ImageJumbotron_Section
            img-path="tutoriales_para_preparar_mezcla brava.png"
            :text="'TUTORIALES MEZCLA BRAVA'"
        ></ImageJumbotron_Section>

        <!-- TUTORIALS DESCRIPTION -->
        <v-row align="center" class="descriptionContainer noSpace" justify="center">
            <v-col align="center">
                <p class="centuryGothicBold screenTitle mt-8">TUTORIALES MEZCLA BRAVA</p>

                <p class="centuryGothic descriptionText">
                    En <b>Mezcla Brava</b> con gusto te apoyamos en lo que requieras en cualquiera de las etapas de la
                    construcción de tu obra.
                    Aquí te presentamos estos sencillos tutoriales para que conozcas un poco más de nuestros productos.
                </p>
            </v-col>
        </v-row>

        <!-- PRODUCTS TUTORIALS -->
        <v-row align="center" class="productsTutorialsRow noSpace" justify="start">
            <v-col align="center" v-for="(tutorial, index) in productsTutorials" :key="index" cols="12" md="4" lg="4" xl="4">
                <v-row align="center" justify="center" class="noSpace">
                    <iframe
                        :src="tutorial.video"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        class="youTubePlayer"
                        frameborder="0"
                        title="YouTube video player"
                    ></iframe>

                    <p class="centuryGothicBold mt-2 mb-8 text-uppercase">{{ tutorial.label }}</p>
                </v-row>
            </v-col>
        </v-row>

        <!-- MACHINES TUTORIALS -->
        <v-row align="center" class="productsTutorialsRow noSpace" justify="start">
            <v-col>
                <v-row align="center" justify="start">
                    <p class="centuryGothicBold screenTitle mt-16">
                        TUTORIALES DE MAQUINARIA
                    </p>
                </v-row>

                <v-row align="center" justify="start" class="noSpace">
                    <v-col align="center" v-for="(tutorial, index) in machinesTutorials" :key="index" cols="12" md="4" lg="4" xl="4">
                        <iframe
                            :src="tutorial.video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                            class="youTubePlayer"
                            frameborder="0"
                            quality=high
                            title="YouTube video player"
                        ></iframe>

                        <p class="centuryGothicBold mt-2 mb-12 text-uppercase">{{ tutorial.label }}</p>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ImageJumbotron_Section from "../../components/sections/ImageJumbotron_Section";

export default {
    name: "Academy",
    components: {
        ImageJumbotron_Section,
    },
    data() {
        return {
            productsTutorials: [
                {video: 'https://www.youtube.com/embed/_bx2WRK8wOM', label: 'Pulido Blanco',},
                {video: 'https://www.youtube.com/embed/r-hB7G0BRZA', label: 'MICRO CONCRETOS',},
                {video: 'https://www.youtube.com/embed/BfaUEkB_8iM ', label: 'LEVANTA MUROS',},
                {video: 'https://www.youtube.com/embed/qL3ymQNqieQ', label: 'ESTUCO PREMIUM',},
                {video: 'https://www.youtube.com/embed/V1kaks3rims', label: 'ESTUCO BLANCO',},
                {video: 'https://www.youtube.com/embed/Vwwpa-73H0Q', label: 'REPELLO GRIS',},
                {video: 'https://www.youtube.com/embed/Ivfio9VH5hY', label: 'coloca pisos y losetas',},
            ],
            machinesTutorials: [
                {video: 'https://www.youtube.com/embed/7m3SddVXmxM', label: 'AMASADORA D50',},
                {video: 'https://www.youtube.com/embed/tPpsSytrt04', label: 'AMASADORA D30',},
                {video: 'https://www.youtube.com/embed/M3pXFLuItCw', label: 'AMASADORA D20',},
                {video: 'https://www.youtube.com/embed/0rOkcbEVq0A', label: 'LANZADORA MONOMIX C04',},
            ],
        }
    },
    metaInfo() {
        return {
            title: 'Mezcla Brava | Tutoriales',
            meta: [
                { vmid: 'description', name: 'description', content: 'Mezcla Brava es parte de la gran familia de GRUPO CALIDRA; la empresa con mayor tradición en la industria de la construcción y orgullosamente mexicanos.' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:image', name: 'og:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'twitter:image', name: 'twitter:image', content: 'https://firebasestorage.googleapis.com/v0/b/mezcla-brava.appspot.com/o/Logo_MezclaBrava_Slogan.png?alt=media&token=ad5e4faf-e91f-48b8-8b75-8eeb204328e2' },
                { vmid: 'og:url', name: 'og:url', content: 'https://www.mezclabrava.com/' },
                { vmid: 'twitter:url', name: 'twitter:url', content: 'https://www.mezclabrava.com/' },
            ]
        }
    },
}
</script>

<style scoped>
.youTubePlayer {
    width: 100%;
    height: 35vh;
}

.descriptionContainer {
    padding: 0 50px !important;
}

.productsTutorialsRow {
    padding: 0 50px !important;
}

.descriptionText {
    margin-left: 15%;
    margin-right: 15%;
}

@media (max-width: 960px) {
    .descriptionContainer {
        padding: 0 25px !important;
    }

    .productsTutorialsRow {
        padding: 0 15px !important;
    }

    .screenTitle {
        text-align: center;
    }

    .descriptionText {
        margin-left: 0;
        margin-right: 0;
    }

}
</style>